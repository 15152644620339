import axios from "axios";
const GW_URL = `https://gw-qa.zymple.io`;
export const giftcardsBaseUrl = `${GW_URL}/promotions/v1/giftcards`;
export const promotionsBaseUrl = `${GW_URL}/promotions/v1`;
export const loungesBaseUrl = `${GW_URL}/lounges/v1`;
export const cashRegisterBaseUrl = `${GW_URL}/caja/v1`;
export const servicesBaseUrl = `${GW_URL}/servicios/v1/Services`;
export const productsBaseUrl = `${GW_URL}/almacen/v1/LocalProducts`;
export const employeesBaseUrl = `${GW_URL}/empleados/v1`;
export const supplierBaseUrl = `${GW_URL}/almacen/v1/Supplier`;
export const warehouseBaseUrl = `${GW_URL}/almacen/v1`;
export const trackingBaseUrl = `${GW_URL}/tracking/v1`;
export const appointmentsBaseUrl = `${GW_URL}/reservas/v1`;
export const clientsBaseUrl = `${GW_URL}/customers/v1`;
export const tokenLocal = localStorage.getItem("access_token");

export const headerAuthorization = {
  headers: {
    Authorization: `Bearer ${tokenLocal}`,
  },
};
export interface ApiResponse {
  isSuccess: boolean;
  status: number;
  data: any;
  message: string;
}

export const handleSuccessfulResponse = (
  response: any,
  successMessage?: string
): ApiResponse => ({
  isSuccess: true,
  status: response.status,
  data: response.data.data,
  message: response?.data?.message || successMessage,
});

export const handleApiError = (
  error: any,
  errorMessage?: string
): ApiResponse => {
  const status = error.response ? error.response.status : 500;
  return {
    isSuccess: false,
    status: error?.response?.status || 500,
    data: null,
    message: error?.response?.data?.message || errorMessage,
  };
};
export const getData = async (
  url: string,
  successMessage?: string,
  errorMessage?: string
): Promise<ApiResponse> => {
  try {
    const response = await axios.get(url, headerAuthorization);
    return handleSuccessfulResponse(response, successMessage);
  } catch (error) {
    return handleApiError(error, errorMessage);
  }
};

export const postData = async (
  url: string,
  data: any,
  successMessage?: string,
  errorMessage?: string
): Promise<ApiResponse> => {
  try {
    const response = await axios.post(url, data, headerAuthorization);
    return handleSuccessfulResponse(response, successMessage);
  } catch (error) {
    return handleApiError(error, errorMessage);
  }
};

export const putData = async (
  url: string,
  data: any,
  successMessage?: string,
  errorMessage?: string
): Promise<ApiResponse> => {
  try {
    const response = await axios.put(url, data, headerAuthorization);
    return handleSuccessfulResponse(response, successMessage);
  } catch (error) {
    return handleApiError(error, errorMessage);
  }
};

export const deleteData = async (
  url: string,
  successMessage?: string,
  errorMessage?: string
): Promise<ApiResponse> => {
  try {
    const response = await axios.delete(url, headerAuthorization);
    return handleSuccessfulResponse(response, successMessage);
  } catch (error) {
    return handleApiError(error, errorMessage);
  }
};
