import React, { useEffect, useState } from "react";
//@ts-ignore
import style from "./DocumentForm.module.css";
import SelectInput from "@Atoms/inputs/selectInput/SelectInput";
import TextInput from "@Atoms/inputs/textInput/TextInput";
import CircularInput from "@Atoms/inputs/CircularInput/CircularInput";
import CustomSelect from "@Molecules/InputPhoneNumber/CustomSelect";
import ValidationInput from "@Atoms/inputs/validationInput/ValidationInput";
import {
  CARNET_DE_EXTRANJERIA,
  DNI,
  FEMENINO,
  MASCULINO,
  OTRO,
  PASAPORTE,
  typeDocuments,
} from "../constants/constants";
import {
  handleInputChange,
  isEmailValid,
  isValidDate,
  sanitizeAndSetForm,
} from "../FormFunctions/FormFunctions";
import DateInput from "@Atoms/inputs/dateInput/DateInput";
import { TimesIcon } from "@Models/icons";
// @ts-ignore
import { Icon } from "@viuti/recursos";

const DocumentForm = ({
  form,
  setForm,
  status,
  edit,
  isLoading,
  categories,
  searchingInReniec
}) => {
  const [propsDocumentNumber, setPropsDocumentNumber] = React.useState({
    label: "Número de documento",
    name: "documentNumber",
    value: form.documentNumber,
    handleChange: (e) => {},
    placeholder: "Número de documento",
    maxLength: 8,
    width: "100%",
    status,
    disabled: edit || isLoading || searchingInReniec,
  });
  const [checked, setChecked] = React.useState(form.sex);

  useEffect(() => {
    setPropsDocumentNumber({
      ...propsDocumentNumber,
      disabled: edit || isLoading || searchingInReniec,
    });
  }, [isLoading, searchingInReniec]);

  useEffect(() => {
    setChecked(form.sex);
  }, [form.sex]);

  const [phoneValues, setPhoneValues] = React.useState({
    codeCountryPhone: "51",
    phoneNumber: form.phoneNumber,
  });

  useEffect(() => {
    setForm({
      ...form,
      sex: checked,
    });
  }, [checked]);

  useEffect(() => {
    setPropsDocumentNumber({
      ...propsDocumentNumber,
      status,
    });
  }, [status]);

  const setDocumentNumberProps = (label, placeholder, maxLength) => {
    setPropsDocumentNumber({
      ...propsDocumentNumber,
      label: label,
      name: "documentNumber",
      value: form.documentNumber,
      handleChange: (e) =>
        sanitizeAndSetForm(e, maxLength, setForm, form, label),
      placeholder: placeholder,
      maxLength: maxLength,
      width: "100%",
      status: status,
      disabled: edit || isLoading || searchingInReniec,
    });
  };

  useEffect(() => {
    switch (form.documentType) {
      case DNI:
        setDocumentNumberProps("Número de documento", "Número de documento", 8);
        break;
      case PASAPORTE:
        setDocumentNumberProps(
          "Número de pasaporte",
          "Número de pasaporte",
          12
        );
        break;
      case CARNET_DE_EXTRANJERIA:
        setDocumentNumberProps(
          "Número de carnet",
          "Número de carnet de extranjería",
          7
        );
        break;
      default:
    }
  }, [form.documentType, form.documentNumber]);

  const handleCheck = (e, sex) => {
    e.preventDefault();
    // if (edit) return;
    setChecked(sex);
  };

  useEffect(() => {
    setForm({
      ...form,
      codeCountryPhone: phoneValues.codeCountryPhone,
      phoneNumber: phoneValues.phoneNumber,
    });
  }, [phoneValues]);

  const [localError, setLocalError] = useState("");

  const handleInputChanges = (e) => {
    const { name, value } = e.target;
    let errorMessage = "";

    if (name === "birthdate") {
      const isValid = isValidDate(value);
      if (!isValid) {
        errorMessage = "La fecha de nacimiento no es válida.";
      }
    }
    setLocalError(errorMessage);
    setForm({ ...form, [name]: value });
  };

  return (
    <section className={style.containerInputs}>
      <article>
        <div>
          <SelectInput
            label={"Tipo de documento"}
            name={"documentType"}
            flex={1}
            value={form.documentType}
            touched={false}
            error={false}
            handleChange={(e) =>
              setForm({ ...form, documentType: e.target.value })
            }
            handleBlur={() => {}}
            items={typeDocuments}
            required={true}
            placeholder={"Tipo de documento"}
            width="100%"
            disabled={edit || isLoading || searchingInReniec}
          />
        </div>
        <div>
          <ValidationInput {...propsDocumentNumber} />
        </div>
      </article>
      <article>
        <div>
          <TextInput
            label={"Nombre"}
            name={"name"}
            value={form.name}
            touched={false}
            error={false}
            handleChange={(e) => {
              const { name, value } = e.target;
              const sanitizedValue = value.replace(/[^a-zA-ZñÑ\s]/g, "");
              setForm({ ...form, [name]: sanitizedValue });
            }}
            placeholder={"Nombre"}
            disabled={isLoading || searchingInReniec}
            keyboardType="text"
            required={true}
            maxLength={8}
            width="100%"
          />
        </div>
        <div>
          <TextInput
            label={"Apellido"}
            name={"lastName"}
            value={form.lastName}
            touched={false}
            error={false}
            handleChange={(e) => {
              const { name, value } = e.target;
              const sanitizedValue = value.replace(/[^a-zA-ZñÑ\s]/g, "");
              setForm({ ...form, [name]: sanitizedValue });
            }}
            placeholder={"Apellido"}
            disabled={isLoading || searchingInReniec}
            keyboardType="text"
            required={true}
            maxLength={8}
            width="100%"
          />
        </div>
      </article>

      <article>
        <div>
          <DateInput
            label={"Fecha de nacimiento"}
            touched={true}
            error={localError}
            disabled={isLoading || searchingInReniec}
            required={true}
            containerStyle={{ width: "100%" }}
            inputStyle={{ width: "100%" }}
            inputProps={{
              value: form.birthdate,
              onChange: handleInputChanges,
              name: "birthdate",
            }}
          />
        </div>
        <div className={style.countrySelectContainer}>
          <label htmlFor="number">Teléfono móvil</label>
          <CustomSelect
            phoneValues={{
              codeCountryPhone: phoneValues.codeCountryPhone,
              phoneNumber: phoneValues.phoneNumber,
            }}
            setPhoneValues={setPhoneValues}
            handleInputChange={(e) =>
              handleInputChange(e, phoneValues, setPhoneValues)
            }
            placeholder="Número de teléfono"
            isDisabled={isLoading || searchingInReniec}
          />
        </div>
      </article>
      <article>
        <div className={style.containerSelectCategory}>
          <SelectInput
            label={"Categoría"}
            name={"categoryClientId"}
            flex={1}
            value={form.categoryClientId}
            touched={false}
            error={false}
            handleChange={(e) =>
              setForm({ ...form, categoryClientId: e.target.value })
            }
            items={categories}
            placeholder={"Selecciona una categoría"}
            width="100%"
            disabled={isLoading || searchingInReniec}
          />
          <span className={style.containerClearCategory}>
            {form.categoryClientId && (
              <button
                className={style.buttonClearCategory}
                disabled={isLoading || searchingInReniec}
                title="Limpiar categoría"
                onClick={() => {
                  setForm({
                    ...form,
                    categoryClientId: "",
                  });
                }}
                type="button"
              >
                <Icon path={TimesIcon} color={"#ACACAC"} size={15} />
              </button>
            )}
          </span>
        </div>
      </article>
      <article>
        <TextInput
          label={"Correo electrónico"}
          name={"email"}
          value={form.email}
          touched={form.email !== ""}
          error={
            form.email && !isEmailValid(form.email)
              ? "No es un correo válido."
              : ""
          }
          handleChange={(e) => {
            const { name, value } = e.target;
            setForm({ ...form, [name]: value });
          }}
          placeholder={"Correo electrónico"}
          disabled={isLoading || searchingInReniec}
          keyboardType="text"
          maxLength={8}
          inputProps={{
            type: "email",
          }}
        />
      </article>
      <article className={style.containerSex}>
        <label className={style.inputLabel}>
          <p>Sexo</p>
          <p className={style.inputRequired}>*</p>
        </label>
        <div className={style.containerButtonsSex}>
          <button
            onClick={(e) => handleCheck(e, FEMENINO)}
            style={{ cursor: isLoading || searchingInReniec ? "default" : "pointer" }}
          >
            <span>Femenino</span>
            <CircularInput
              checked={checked === FEMENINO}
              id={FEMENINO}
              onClick={(e) => handleCheck(e, FEMENINO)}
              locked={isLoading || searchingInReniec}
            />
          </button>
          <button
            onClick={(e) => handleCheck(e, MASCULINO)}
            style={{ cursor: isLoading || searchingInReniec  ? "default" : "pointer" }}
          >
            <span>Masculino</span>
            <CircularInput
              checked={checked === MASCULINO}
              id={MASCULINO}
              onClick={(e) => handleCheck(e, MASCULINO)}
              locked={isLoading || searchingInReniec}
            />
          </button>
          <button
            onClick={(e) => handleCheck(e, OTRO)}
            style={{ cursor: isLoading || searchingInReniec  ? "default" : "pointer" }}
          >
            <span>Otro</span>
            <CircularInput
              checked={checked === OTRO}
              id={OTRO}
              onClick={(e) => handleCheck(e, OTRO)}
              locked={isLoading || searchingInReniec}
            />
          </button>
        </div>
      </article>
    </section>
  );
};

export default DocumentForm;
