import React, { useEffect } from "react";
//@ts-ignore
import style from "./RucForm.module.css";
import SelectInput from "@Atoms/inputs/selectInput/SelectInput";
import TextInput from "@Atoms/inputs/textInput/TextInput";
import CustomSelect from "@Molecules/InputPhoneNumber/CustomSelect";
import { typeDocuments } from "../constants/constants";
import ValidationInput from "@Atoms/inputs/validationInput/ValidationInput";
import {
  handleInputChange,
  isEmailValid,
  sanitizeAndSetForm,
} from "../FormFunctions/FormFunctions";
import { TimesIcon } from "@Models/icons";
// @ts-ignore
import { Icon } from "@viuti/recursos";

const RucForm = ({ form, setForm, status, edit, isLoading, categories, searchingInReniec }) => {
  const [phoneValues, setPhoneValues] = React.useState({
    codeCountryPhone: "51",
    phoneNumber: form.phoneNumber,
  });
  const setTypeDocument = (e) => {
    setForm({
      ...form,
      documentType: e.target.value,
    });
  };

  useEffect(() => {
    setForm({
      ...form,
      codeCountryPhone: phoneValues.codeCountryPhone,
      phoneNumber: phoneValues.phoneNumber,
    });
  }, [phoneValues]);

  useEffect(() => {
    setForm({
      ...form,
      name: "",
      address: "",
      email: "",
      categoryClientId: "",
    });
  }, [form.documentNumber]);

  return (
    <section className={style.containerInputs}>
      <article>
        <div>
          <SelectInput
            label={"Tipo de documento"}
            name={"documentType"}
            flex={1}
            value={form.documentType}
            touched={false}
            error={false}
            handleChange={setTypeDocument}
            handleBlur={() => {}}
            disabled={edit || isLoading || searchingInReniec}
            items={typeDocuments}
            required={true}
            placeholder={"Tipo de documento"}
            width="100%"
          />
        </div>
        <div>
          <ValidationInput
            label={"Número de RUC"}
            name={"documentNumber"}
            value={form.documentNumber}
            handleChange={(e) =>
              sanitizeAndSetForm(e, 11, setForm, form, "Número de RUC")
            }
            placeholder={"Número de RUC"}
            maxLength={11}
            width="100%"
            status={status}
            disabled={edit || isLoading || searchingInReniec}
          />
        </div>
      </article>
      <article>
        <TextInput
          label={"Razón social"}
          name={"name"}
          value={form.name}
          touched={false}
          error={false}
          handleChange={(e) => {
            const { name, value } = e.target;
            setForm({ ...form, [name]: value });
          }}
          placeholder={"Importaciones y exportaciones Latinoamericanas del sur"}
          disabled={isLoading || searchingInReniec}
          keyboardType="text"
          required={true}
          width="100%"
        />
      </article>
      <article>
        <div>
          <TextInput
            label={"Dirección"}
            name={"address"}
            value={form.address}
            touched={false}
            error={false}
            handleChange={(e) => {
              const { name, value } = e.target;
              setForm({ ...form, [name]: value });
            }}
            placeholder={"Calle Los Arces 123 Miraflores, Lima - Perú"}
            disabled={isLoading || searchingInReniec}
            keyboardType="text"
            required={true}
          />
        </div>
        <div className={style.countrySelectContainer}>
          <label htmlFor="number">Celular</label>
          <CustomSelect
            phoneValues={{
              codeCountryPhone: phoneValues.codeCountryPhone,
              phoneNumber: phoneValues.phoneNumber,
            }}
            setPhoneValues={setPhoneValues}
            handleInputChange={(e) =>
              handleInputChange(e, phoneValues, setPhoneValues)
            }
            placeholder="Número de teléfono"
            isDisabled={isLoading || searchingInReniec}
          />
        </div>
      </article>
      <article>
        <div className={style.containerSelectCategory}>
          <SelectInput
            label={"Categoría"}
            name={"categoryClientId"}
            flex={1}
            value={form.categoryClientId}
            touched={false}
            error={false}
            handleChange={(e) =>
              setForm({ ...form, categoryClientId: e.target.value })
            }
            items={categories}
            placeholder={"Selecciona una categoría"}
            width="100%"
            disabled={isLoading || searchingInReniec}
          />
          <span className={style.containerClearCategory}>
            {form.categoryClientId && (
              <button
                className={style.buttonClearCategory}
                disabled={isLoading || searchingInReniec}
                title="Limpiar categoría"
                onClick={() => {
                  setForm({
                    ...form,
                    categoryClientId: "",
                  });
                }}
                type="button"
              >
                <Icon path={TimesIcon} color={"#ACACAC"} size={15} />
              </button>
            )}
          </span>
        </div>
      </article>
      <article>
        <TextInput
          label={"Correo electrónico"}
          name={"email"}
          value={form.email}
          touched={form.email !== ""}
          error={
            form.email && !isEmailValid(form.email)
              ? "Debe contener al menos @ y ."
              : ""
          }
          handleChange={(e) => {
            const { name, value } = e.target;
            setForm({ ...form, [name]: value });
          }}
          placeholder={"Correo electrónico"}
          disabled={isLoading || searchingInReniec}
          keyboardType="text"
          maxLength={8}
          inputProps={{
            type: "email",
          }}
        />
      </article>
    </section>
  );
};

export default RucForm;
