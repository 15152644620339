import React, { useEffect, useRef, useState } from "react";
import Form from "../Form/Form";
import {
  CARNET_DE_EXTRANJERIA,
  DNI,
  PASAPORTE,
  RUC,
} from "../Form/constants/constants";
import {
  validateCarnetDeExtranjeriaAndPasaport,
  validateDNIAndRUC,
} from "./validations/validations";
import { postCreateNewClient } from "@Services/clients/postCreateNewClient";
// @ts-ignore
import { SlideNotification } from "@viuti/recursos";
import { useClientsCategories } from "./hook/useClientsCategories";

const FormCreateNewClient = ({ isDisabled, setIsDisabled, nextClick }) => {
  const [isLoadingCreateNewClient, setIsLoadingCreateNewClient] =
    useState(false);
  const [searchingInReniec, setSearchingInReniec] =
    useState(false);
  const [form, setForm] = useState({
    name: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    codeCountryPhone: "51",
    documentType: DNI,
    documentNumber: "",
    categoryClientId: "",
    sex: "",
    birthdate: "",
    address: "",
  });
  const [status, setStatus] = useState({
    error: false,
    validated: false,
    loading: false,
    message: "",
  });
  const [stateResponse, setStateResponse] = useState<any>({
    message: "",
    status: 0,
  });
  const { categories } = useClientsCategories(setStateResponse);

  const modalRef = useRef(null);
  const ESCAPE_KEY = 27;
  useEffect(() => {
    setForm({
      name: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      codeCountryPhone: "51",
      documentType: DNI,
      documentNumber: "",
      categoryClientId: "",
      sex: "",
      birthdate: "",
      address: "",
    });
  }, [isDisabled]);

  useEffect(() => {
    setStatus({
      error: false,
      validated: false,
      loading: false,
      message: "",
    });
  }, [form.documentNumber, form.documentType]);

  const validateDocumentNumber = async () => {
    if (
      (form.documentType === DNI && form.documentNumber.length === 8) ||
      (form.documentType === RUC && form.documentNumber.length === 11)
    ) {
      setSearchingInReniec(true)
      await validateDNIAndRUC(setStatus, status, setForm, form);
      setSearchingInReniec(false)
    } else if (
      form.documentType === CARNET_DE_EXTRANJERIA &&
      form.documentNumber.length === 7
    ) {
      setSearchingInReniec(true)
      await validateCarnetDeExtranjeriaAndPasaport(setStatus, status, form);
      setSearchingInReniec(false)
    } else if (
      form.documentType === PASAPORTE &&
      form.documentNumber.length > 5
    ) {
      setSearchingInReniec(true)
      await validateCarnetDeExtranjeriaAndPasaport(setStatus, status, form);
      setSearchingInReniec(false)
    }
  };

  useEffect(() => {
    validateDocumentNumber();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.documentNumber]);

  const postNewClient = async () => {
    setIsLoadingCreateNewClient(true);
    const res = await postCreateNewClient(form);
    if (res.isSuccess) {
      nextClick(res.data.result);
      setStateResponse({
        message: `Cliente ${form.name} creado de manera exitosa`,
        status: 200,
      });
      setIsDisabled(false);
      setIsLoadingCreateNewClient(false);
    } else {
      setStateResponse({ message: res.message, status: 400 });
      setIsLoadingCreateNewClient(false);
    }
  };

  // useEffect for click outside
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        setIsDisabled(false);
      }
    };

    const handleKeyPress = (e) => {
      if (e.keyCode === ESCAPE_KEY) {
        setIsDisabled(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [modalRef, setIsDisabled]);

  return (
    <>
      <Form
        backClick={() => setIsDisabled(false)}
        nextClick={postNewClient}
        form={form}
        setForm={setForm}
        status={status}
        textButton="Guardar"
        isLoading={isLoadingCreateNewClient}
        categories={categories}
        searchingInReniec={searchingInReniec}
      />
      <SlideNotification
        state={stateResponse}
        clearStatus={() => setStateResponse({ message: "", status: 0 })}
      />
    </>
  );
};

export default FormCreateNewClient;
