import React, { useEffect } from "react";
import TextInput from "@Atoms/inputs/textInput/TextInput";
//@ts-ignore
import style from "./style/searchWithCards.module.css";
import SearchCard from "./components/searchCard/SearchCard";
import { MagnifyingGlassIcon, TimesIcon } from "@Models/icons";
import { ISearchWithCards } from "./interfaces/SearchWithCards";
import useFilter from "./hook/useFilter";
import Icon from "@Atoms/Icon/Icon";

const textInputPropsDefault = {
  label: "Nombre",
  placeholder: "buscar o agregar una categoría",
  value: "",
};

const SearchWithCards = ({
  textInputProps = textInputPropsDefault,
  dataToFiltered = [],
  handleIntro = (textValue) => {},
  cards = [],
  setCards = (e) => {},
  hasIntro = true,
  messageButtonCreate = "Pulsa intro para crear una nueva categoría.",
  error = "",
  touched = false,
  handleBlur = (e) => {},
}: ISearchWithCards) => {
  const [textValue, setTextValue] = React.useState("");
  const [results, setResults] = React.useState([]);
  const [allData, setAllData] = React.useState(dataToFiltered);
  const resultsRef = React.useRef(null);

  useFilter(allData, textValue, cards, setResults, setCards);

  useEffect(() => setAllData(dataToFiltered), [dataToFiltered]);

  const handleOutsideClick = (e) => {
    if (resultsRef.current && !resultsRef.current.contains(e.target)) {
      // Clicked outside the results, close the modal and reset input value
      setResults([]);
      setTextValue("");
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const onKeyDown = (e: any) => {
    if (e.key === "Enter" && hasIntro) {
      setTextValue("");
      setResults([]);
      handleIntro(textValue);
    }
  };

  const textProps = {
    ...textInputProps,
    value: textValue,
    handleChange: (e: any) => setTextValue(e.target.value),
    leftIcon: MagnifyingGlassIcon,
    error,
    touched,
    handleBlur,
  };

  const addCard = (id) => {
    const isCardExist = cards.find((card) => card.id === id);
    setTextValue("");
    setResults([]);

    if (isCardExist) return;

    setTextValue("");

    const card = allData.find((data) => data.id === id);
    setCards([...cards, card]);
  };

  return (
    <div className={style.search} onKeyDown={onKeyDown}>
      <TextInput {...textProps} />
      {textValue && (
        <div ref={resultsRef}>
          <SearchCard
            results={results}
            addCard={addCard}
            setTextValue={setTextValue}
            setResults={setResults}
            hasIntro={hasIntro}
            cards={cards}
            messageButtonCreate={messageButtonCreate}
          />
        </div>
      )}
      {cards.length > 0 && (
        <section className={style.cardContainer}>
          {cards.length !== 0 &&
            cards.map((card) => (
              <article key={card.id}>
                {card.name.toUpperCase()}
                <button
                  onClick={() =>
                    setCards(cards.filter((c) => c.id !== card.id))
                  }
                >
                  <Icon
                    size={12}
                    path={TimesIcon}
                    alt={"close icon"}
                    color={"#45348e"}
                  />
                </button>
              </article>
            ))}
        </section>
      )}
    </div>
  );
};

export default SearchWithCards;
